import { create } from "apisauce";

const INSPECTION_PREFIX = "/inventory";
const INFERENCE_BASE_URL = "https://inference-engine.autochek.africa/api/v1";
const PROD_BASE_URL = "https://api-prod.autochek.africa/v1";
const STAGING_BASE_URL = "https://api.staging.myautochek.com/v1";
const QA_BASE_URL = "https://api.qa.myautochek.com/v1";

let url;
if (process.env.NODE_ENV === "development") {
    url = STAGING_BASE_URL;
}
if (process.env.NODE_ENV === "production") {
    url = PROD_BASE_URL;
}
if (process.env.NODE_ENV === "test") {
    url = QA_BASE_URL;
}

console.log("node env", process.env.NODE_ENV);
const Http = create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
    },
});

const HttpTruPrice = create({
    baseURL: `${INFERENCE_BASE_URL}`,
    headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
    },
});

Http.addResponseTransform((res) => {
    if (res.ok) {
        return res.data;
    } else {
        switch (res.problem) {
            case "CLIENT_ERROR":
                return (res.data = { ...res.data, statusCode: res.status });
            case "NETWORK_ERROR":
                return (res.data = {
                    message: "Network Error! Kindly Try again. ",
                });
            default:
                return (res.data = {
                    message: "Unknown error occured!. Kindly try later",
                });
        }
    }
});

HttpTruPrice.addResponseTransform((res) => {
    if (res.ok) {
        return res.data;
    } else {
        switch (res.problem) {
            case "CLIENT_ERROR":
                return (res.data = { ...res.data, statusCode: res.status });
            case "NETWORK_ERROR":
                return (res.data = {
                    message: "Network Error! Kindly Try again. ",
                });
            default:
                return (res.data = {
                    message: "Unknown error occured!. Kindly try later",
                });
        }
    }
});

export const getCarMake = async (pageNumber = 1) => {
    const response = await Http.get(
        `${INSPECTION_PREFIX}/make?page_size=10&page_number=${pageNumber}`
    );
    return response;
};

export const getCarModel = async (id) => {
    const response = await Http.get(
        `${INSPECTION_PREFIX}/model?make_id=${id}&page_size=1000`
    );
    return response;
};

export const loginUser = async (data) => {
    const response = await Http.post(`/auth/login`, data);
    return response;
};

export const updatePrice = async (payload) => {
    const response = await HttpTruPrice.put("/car_pricing/tru_price", payload);
    return response;
};

export const getPricing = async (payload) => {
    const response = await HttpTruPrice.post("/car_pricing/tru_price", payload);
    return response;
};
