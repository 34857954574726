import React from "react";
import { BrowserRouter } from "react-router-dom";
import MyErrorBoundary from "./components/errorboundary";
import Routes from "./routes";
import "./styles/main.scss";

export default function Main() {
    return (
        <MyErrorBoundary>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </MyErrorBoundary>
    );
}
