import { Button } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router";

export default function Welcomebar() {
    const user = JSON.parse(localStorage.getItem("user-data"));
    const history = useHistory();
    const location = useLocation();

    const authority = JSON.parse(localStorage.getItem("authority"));

    return (
        <div className="welcomebar">
            <div className="welcome-message">
                <span>
                    Welcome,{" "}
                    {`${(user && user?.firstname) || ""} ${
                        (user && user?.lastname) || ""
                    }`}
                </span>

                {(authority &&
                    authority.roles &&
                    authority.roles.includes("TRUPRICE ADMIN")) ||
                authority.roles.includes("ADMIN") ? (
                    <>
                        {location.pathname !== "/add-price" ? (
                            <Button
                                type="primary"
                                onClick={() => history.push("/add-price")}
                            >
                                Add Price
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => history.push("/")}
                            >
                                View Pricing
                            </Button>
                        )}
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
