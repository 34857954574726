import { getCarMake } from "../api";

export function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = 1970;
    var years = [];

    for (var i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
}

export async function fetchAllMakes() {
    const firstMakeListRes = await getCarMake();
    let isValid = false;
    if (!firstMakeListRes.ok) return { isValid };
    const {
        pagination: { total },
    } = firstMakeListRes.data;
    const numOfPages = Math.ceil(total / 10);
    const makeList = firstMakeListRes.data.makeList;
    if (numOfPages === 1) return { isValid: true, makeList };
    let result = new Array(numOfPages)
        .fill(1)
        .slice()
        .map((_, i) => getCarMake(i + 2));
    result = await Promise.all(result);
    isValid = !result.some((data) => !data.ok);
    if (isValid)
        makeList.push(...result.map((data) => data.data.makeList).flat());
    return {
        isValid,
        makeList,
    };
}
