import styled from "styled-components";

export const Container = styled.div`
    font-family: "Nunito Sans", sans-serif;
    background-color: #f4f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;

    .spin{
        position: absolute;
        top: 100;
        left: 50;
    }
`;

export const Content = styled.div`
    height: 600px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    background: "red";
    border-radius: 12px;
    overflow: hidden;
    min-width: 800px;
`;

export const LeftCardContent = styled.div`
    background-position: center;
    background-size: cover;
    height: 100%;
    mix-blend-mode: multiply;

    display: grid;
    align-items: center;
    padding-left: 70px;

    .brand {
        width: 180px;

        img {
            height: auto;
            width: 100%;
        }
    }

    .product-type,
    .motto {
        font-size: 16px! important;
        color: #ffb619;
        text-transform: uppercase;

        h3 {
            margin-bottom: -14px;
            padding: 0;
            font-size: 18px;
            position: relative;
            text-transform: uppercase;
            font-weight: 600;
            color: #ffb619;

            &:before {
                content: "";
                position: absolute;
                top: -10px;
                height: 3px;
                width: 20%;
                background: #ffb619;
            }
        }

        span {
            font-size: 47px;
            text-transform: uppercase;
            font-weight: 700;
            margin-top: 100px;
        }
    }

    .motto {
        font-size: 10px;
    }
`;

export const RightCardContent = styled.div`
    background: #fff;
    padding: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .welcome-title {
        font-weight: 600;
        font-size: 22px;
        text-align: center;
    }

    .welcome-brief {
        color: #8b8b8b;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
    }

    .form-container {
        .form-footer-message {
            margin-top: 24px;
            color: #8b8b8b;
            text-align: center;
            font-size: 14px;

            span {
                color: #30345e;
                cursor: pointer;
            }
        }

        .btn {
            background: #feb617;
            width: 100%;
            height:45px;
        }
    }
`;
