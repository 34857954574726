import React, { useEffect, useState } from "react";
import { Form, Button, Select, Input, Radio, message } from "antd";
import "../styles/styles.scss";
import Navbar from "../components/navbar/Navbar";
import Welcomebar from "../components/Welcomebar/Welcomebar";
import { getCarModel, updatePrice } from "../api";
import { useUpdateEffect } from "react-use";
import { generateArrayOfYears, fetchAllMakes } from "../utils";
import { useForm } from "antd/lib/form/Form";
import { motion } from "framer-motion";
import { useLocation } from "react-router";

const { Option } = Select;

export default function AddPrice() {
    const [make, setMake] = useState([]);
    const [fetchingModel, setFetchingModel] = useState(false);
    const [model, setModel] = useState([]);
    const [currentModelData, setCurrentModelData] = useState({});
    const [currentMakeData, setCurrentMakeData] = useState({});
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    const [form] = useForm();

    const handleMakeChange = (value) => {
        const foundMake = make.find((el) => el.id === value);
        setCurrentMakeData({
            id: value,
            name: foundMake.name,
        });
    };

    const handleModelChange = (value) => {
        const foundModel = model.find((el) => el.id === value);
        setCurrentModelData({
            id: value,
            name: foundModel.name,
        });
    };

    async function handlePrice(payload) {
        setLoading(true)
        try {
            const result = await updatePrice({
                year_of_manufacture: payload.year_of_manufacture,
                make: currentMakeData.name,
                model: currentModelData.name,
                price: +payload.price,
                condition: payload.condition,
            });
            if (result.ok) {
                setLoading(false);
                message.success("added successfully");
                form.resetFields();

            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    useEffect(() => {
        async function getMake() {
            const result = await fetchAllMakes();
            if (result.isValid) {
                setMake(result.makeList);
            }
        }
        getMake();
    }, [location.pathname]);
    useUpdateEffect(() => {
        async function getModel() {
            setFetchingModel(true);
            const result = await getCarModel(currentMakeData.id);
            if (result.ok) {
                setFetchingModel(false);
                return setModel(result.data.modelList);
            }
            setFetchingModel(false);
        }

        getModel();
    }, [currentMakeData]);


    return (
        <>
            <Navbar />
            <Welcomebar />
            <motion.div
                key={location.pathname}
                initial={{ y: -40, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
            >
                <div className="container">
                    <div className="form">
                        <div className="heading">
                            <span>Add Price</span>
                        </div>
                        <Form form={form} onFinish={handlePrice}>
                            <div className="form-container">
                                <div className="form-control">
                                    <label htmlFor="make">Make</label>
                                    <Form.Item
                                        name="make"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Search Make"
                                            optionFilterProp="children"
                                            onChange={handleMakeChange}
                                        >
                                            {make &&
                                                make?.map((el) => {
                                                    return (
                                                        <Option value={el.id}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="form-control">
                                    <label htmlFor="make">Model</label>
                                    <Form.Item
                                        name="model"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Search Model"
                                            optionFilterProp="children"
                                            onChange={handleModelChange}
                                            loading={fetchingModel}
                                            disabled={
                                                fetchingModel ||
                                                Object.keys(currentMakeData) < 1
                                            }
                                        >
                                            {model &&
                                                model?.map((el) => {
                                                    return (
                                                        <Option value={el.id}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="form-control">
                                    <label htmlFor="make">
                                        Year of Manufacture
                                    </label>
                                    <Form.Item
                                        name="year_of_manufacture"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select year type"
                                            optionFilterProp="children"
                                        >
                                            {generateArrayOfYears().map(
                                                (el) => {
                                                    return (
                                                        <Option value={el}>
                                                            {el}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="form-control">
                                    <label htmlFor="make">Price</label>
                                    <Form.Item
                                        name="price"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Price" />
                                    </Form.Item>
                                </div>
                                <div className="form-control">
                                    <label htmlFor="make">Condition</label>
                                    <Form.Item
                                        name="condition"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            size="large"
                                            buttonStyle="solid"
                                            value="condition"
                                        >
                                            <Radio.Button value="Foreign Used">
                                                Foreign Used
                                            </Radio.Button>
                                            <Radio.Button value="Locally Used">
                                                Locally Used
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </motion.div>
        </>
    );
}
