import React, { useState } from "react";
import {
    Container,
    Content,
    LeftCardContent,
    RightCardContent,
} from "./style.js";
import { Spinner } from "../../components/spinner";
import { Button, Input, message, Form } from "antd";
import { loginUser } from "../../api";
import Logo from "../../assets/img/yellowlogo.svg";
import Building from "../../assets/img/building.png";
import { useHistory } from "react-router";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [, setData] = useState({});
    const history = useHistory();

    async function handleLogin(data) {
        setLoading(true);
        const result = await loginUser(data);
        if (result.ok) {
            setData(result.data);
            setLoading(false);
            localStorage.setItem("auth-token", result.data.token);
            localStorage.setItem("user-data", JSON.stringify(result.data.user));
            localStorage.setItem(
                "authority",
                JSON.stringify(result.data.authority)
            );
            message.success("successful login");
            return history.push("/");
        }
        setLoading(false);
        message.error(result.data.message);
    }

    return (
        <Container>
            {/* {loading && <Spinner />} */}
            <Content>
                <LeftCardContent
                    style={{
                        backgroundImage: `linear-gradient(#181b3ae6, #181b3ae6), url(${Building})`,
                    }}
                >
                    <div className="brand">
                        <img src={Logo} alt="brand" />
                    </div>
                    <div className="product-type">
                        <h3>Pricing</h3>
                        <span>Portal</span>
                    </div>
                    <div className="motto">TruPrice</div>
                </LeftCardContent>

                <RightCardContent>
                    <div className="welcome-title">Welcome!</div>
                    <div className="welcome-brief">
                        Please sign in to view car pricing
                    </div>

                    <Form onFinish={handleLogin}>
                        <div className="form-container">
                            <div className="form-control">
                                <label htmlFor="make">Email</label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            message:
                                                "Email format is incorrect",
                                        },
                                        {
                                            required: true,
                                            message: "Email is required field!",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Your email"
                                    />
                                </Form.Item>
                            </div>

                            <div className="form-control">
                                <label htmlFor="make">Password</label>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Password is a required field",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Your Password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                    />
                                </Form.Item>
                            </div>
                            {loading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Spinner />
                                </div>
                            ) : (
                                <Button
                                    disabled={loading}
                                    type=""
                                    htmlType="submit"
                                    className="primary btn"
                                >
                                    LOGIN
                                </Button>
                            )}
                        </div>
                    </Form>
                </RightCardContent>
            </Content>
        </Container>
    );
};

export default Login;
