import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import App from "./App";
import Login from "./pages/login";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";
import { Spinner } from "./components/spinner";
import AddPrice from "./pages/add-price";

export default function Routes() {
    return (
        <Switch>
            <ProtectedRoute component={App} exact path="/" />
            <ProtectedRoute component={AddPrice} exact path="/add-price" />
            <Route component={Login} exact path="/login" />
        </Switch>
    );
}

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem("auth-token");
    const data = localStorage.getItem("user-data");
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isTokenValid(token)) {
            setLoading(true);
            history.push("/login");
        }
        setLoading(false);
    }, [token, data, history, setLoading]);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                }}
            >
                <Spinner />
            </div>
        );
    }

    return <Component {...rest} />;
};

export const isTokenValid = (token) => {
    if (token) {
        const decodedToken = jwtDecode(token);
        return Date.now() <= decodedToken?.exp * 1000;
    }

    return false;
};
