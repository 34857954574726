import React from "react";
import { useHistory } from "react-router";
import { ReactComponent as LogoIcon } from "../../assets/img/logo.svg";

export default function Navbar() {
    const history = useHistory();

    function logout() {
        localStorage.clear("auth-token");
        localStorage.clear("user-token");
        history.push("/login");
    }

    return (
        <div className="navbar">
            <div className="icon-holder">
                <LogoIcon  className="icon" />
            </div>
            <span className="logout" onClick={() => logout()}>
                Logout
            </span>
        </div>
    );
}
