/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from "react";
import "./styles/styles.scss";
import { Form, Button, Select } from "antd";
import { Spinner } from "./components/spinner";
import { useUpdateEffect } from "react-use";
import { getPricing, getCarModel } from "./api";
import { generateArrayOfYears, fetchAllMakes } from "./utils";
import PriceInfo from "./components/price-info";
import Navbar from "./components/navbar/Navbar";
import Welcomebar from "./components/Welcomebar/Welcomebar";
import { useLocation } from "react-router";
import { motion } from "framer-motion";

const { Option } = Select;

function App() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState({});
    const [make, setMake] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [currentMakeData, setCurrentMakeData] = useState({});
    const [currentModelData, setCurrentModelData] = useState({});
    const [fetchingModel, setFetchingModel] = useState(false);
    const [fetchingMake, setFetchingMake] = useState(false);
    const [model, setModel] = useState([]);
    const myRef = useRef(null);
    const location = useLocation();

    async function handlePredict(payload) {
        setLoading(true);
        setSuccess(false);

        try {
            const result = await getPricing({
                year_of_manufacture: payload.year_of_manufacture,
                make: currentMakeData.name,
                model: currentModelData.name,
            });
            if (result.ok) {
                setSuccess(true);
                setErrorMsg("");
                setData({ ...result.data.data });
                return setLoading(false);
            }
            if (result?.data?.status === "fail") {
                setLoading(false);
                setSuccess(false);
                setErrorMsg(
                    result.data.message ||
                        "An Unknown errored Occured! Kindly try again!"
                );
            }
        } catch (err) {
            setLoading(false);
        }
    }

    useUpdateEffect(() => {
        try {
            myRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        } catch (err) {}
    }, [success]);

    useEffect(() => {
        async function getMake() {
            setFetchingMake(true);

            const result = await fetchAllMakes();
            if (result.isValid) {
                setMake(result.makeList);
            setFetchingMake(true);

            }
            setFetchingMake(false);

        }
        getMake();
    }, [location.pathname]);

    useUpdateEffect(() => {
        async function getModel() {
            setFetchingModel(true);
            const result = await getCarModel(currentMakeData.id);
            if (result.ok) {
                setFetchingModel(false);
                return setModel(result.data.modelList);
            }
            setFetchingModel(false);
        }

        getModel();
    }, [currentMakeData]);

    const handleMakeChange = (value) => {
        const foundMake = make.find((el) => el.id === value);
        setCurrentMakeData({
            id: value,
            name: foundMake.name,
        });
    };

    const handleModelChange = (value) => {
        const foundModel = model.find((el) => el.id === value);
        setCurrentModelData({
            id: value,
            name: foundModel.name,
        });
    };

    function handleResult() {
        let content;
        if (loading) {
            content = (
                <div className="spinner">
                    <Spinner />
                </div>
            );
        } else if (errorMsg !== "") {
            content = <h3 style={{ textAlign: "center" }}>{errorMsg}</h3>;
        } else if (success === false) {
            content = "";
        } else {
            content = (
                <div className="result" ref={myRef}>
                    <div className="price-table">
                        <span className="price-type">Prices (₦)</span>
                        <div className="">
                            <PriceInfo
                                type={
                                    Object.keys(data.price)[0] ===
                                        "fair_condition" && "Fair"
                                }
                                price={Intl.NumberFormat("en-NG").format(
                                    data.price.fair_condition
                                )}
                            />
                            <PriceInfo
                                type={
                                    Object.keys(data.price)[1] ===
                                        "good_condition" && "Good"
                                }
                                price={Intl.NumberFormat("en-NG").format(
                                    data.price.good_condition
                                )}
                            />

                            {/* {data &&
                                Object.keys(data.price)
                                    // .sort((a, b) => a[1].min - b[1].max)
                                    // .slice(0, 2)
                                    .map((el) => {
                                        return (
                                            <PriceInfo
                                                type={handleTypeName(el[0])}
                                                min={Intl.NumberFormat(
                                                    "en-NG"
                                                ).format(el[1].min)}
                                                max={Intl.NumberFormat(
                                                    "en-NG"
                                                ).format(el[1].max)}
                                                average={calculateAverage(
                                                    el[1]
                                                )}
                                            />
                                        );
                                    })} */}
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    }

    return (
        <>
            <Navbar />
            <Welcomebar />
            <motion.div
                key={location.pathname}
                initial={{ y: -40, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
            >
                <div className="container">
                    <div className="form">
                        <div className="heading">
                            <span>Pricing Portal</span>
                        </div>
                        <Form onFinish={handlePredict}>
                            <div className="form-container">
                                <div className="form-control">
                                    <label htmlFor="make">Make</label>
                                    <Form.Item
                                        name="make"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Search Make"
                                            optionFilterProp="children"
                                            onChange={handleMakeChange}
                                            loading={fetchingMake}
                                        >
                                            {make &&
                                                make?.map((el) => {
                                                    return (
                                                        <Option value={el.id} key={el.id}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="form-control">
                                    <label htmlFor="make">Model</label>
                                    <Form.Item
                                        name="model"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Search Model"
                                            optionFilterProp="children"
                                            onChange={handleModelChange}
                                            loading={fetchingModel}
                                            disabled={
                                                fetchingModel ||
                                                Object.keys(currentMakeData) < 1
                                            }
                                        >
                                            {model &&
                                                model?.map((el) => {
                                                    return (
                                                        <Option value={el.id} key={el.id}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="form-control">
                                    <label htmlFor="make">
                                        Year of Manufacture
                                    </label>
                                    <Form.Item
                                        name="year_of_manufacture"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select year type"
                                            optionFilterProp="children"
                                        >
                                            {generateArrayOfYears().map(
                                                (el) => {
                                                    return (
                                                        <Option value={el} key={el}>
                                                            {el}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </Form>
                    </div>
                    {handleResult()}
                </div>
            </motion.div>
        </>
    );
}

export default App;
