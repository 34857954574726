/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { ReactComponent as LogoIcon } from "../../assets/img/logo.svg";

/**
 *@description A class that shows up when the app crashes
 *
 * @class ErrorBoundary
 * @extends {Component}
 */
class ErrorBoundary extends Component {
    state = {
        hasError: false,
    };

    /**
     *
     *
     * @static
     * @param {*} error
     * @returns
     * @memberof ErrorBoundary
     */
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    /**
     *
     *
     * @param {*} error
     * @param {*} info
     * @memberof ErrorBoundary
     */

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error, info);
    }

    /**
     *
     *
     * @returns
     * @memberof ErrorBoundary
     */
    render() {
        if (this.state?.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-boundary">
                    <div
                        className="error-content"
                        style={{
                            display: "flex",
                            height: "100vh",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <LogoIcon style={{ margin: "50px 0" }} />
                        <span  style={{fontSize: "19px"}}>
                            An Unknown error just occured, Kindly try again later.
                        </span>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
