import React from "react";
// import { DownOutlined } from "@ant-design/icons";

export default function PriceInfo({ type, price }) {
    // const [showMore, setShowMore] = useState(false);

    return (
        <div className="quality-type">
            <span className="type-name">
                {type}&nbsp;
                Condition
            </span>
            <div className="min-max white-1">
                <span className="min">Average</span>
                <span className="min-value">{price}</span>
            </div>
            {/* <div className="min-max white-1">
                <span className="min">Average</span>
                <span className="min-value">{average}</span>
                <DownOutlined
                    className={`down-icon ${showMore ? "rotate" : ""}`}
                    onClick={() => setShowMore(!showMore)}
                />
            </div> */}
            {/* <div className={`other-price ${!showMore ? "show-price" : ""}`}>
                <div className="values white-2">
                    <span className="max">Min</span>
                    <span className="max-value">{min}</span>
                </div>
                <div className="min-max white-3">
                    <span className="min">Max</span>
                    <span className="min-value">{max}</span>
                </div>
            </div> */}
        </div>
    );
}
